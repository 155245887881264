import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import COLOR from "./../../Style";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isSessionSuccess } from "../../StateManagement/sessionSlice";

export default function WelcomeTryout() {
  const classes = useStyles();
  const history = useHistory();
  const isLoggedIn = useSelector(isSessionSuccess);

  // start -- dalam rangka permintaan sesuai whatsapp 20 November 2024
  useEffect(() => {
    history.push("/tryout");
  }, []);
  // end -- dalam rangka permintaan sesuai whatsapp 20 November 2024

  return (
    <div className={classes.container}>
      {isLoggedIn ? (
        <>
          <div
            className={classes.item}
            onClick={() => history.push("/product/tryout")}
          >
            Beli Tiket Try Out
          </div>
          <div className={classes.item} onClick={() => history.push("/tryout")}>
            Kerjakan Try Out
          </div>
        </>
      ) : (
        <>
          <div
            className={classes.link}
            onClick={() => history.push("/login?redirect=welcome-tryout")}
          >
            Log In ke Aplikasi
          </div>
          <div className={classes.notif}>
            *Silakan Login terlebih dahulu untuk membeli tiket try out.{" "}
            <a href="/pendaftaran">klik untuk daftar akun</a>.
          </div>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    color: COLOR.coklatTua,
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    minHeight: document.documentElement.clientHeight - 474,
    "@media (max-width:425px)": {
      minHeight: document.documentElement.clientHeight - 590,
    },
  },
  item: {
    backgroundColor: COLOR.kuning,
    marginBottom: 20,
    padding: "15px 30px",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
    },
  },
  link: {
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  notif: {
    marginTop: 10,
    textAlign: "center",
    padding: 20,
  },
});
